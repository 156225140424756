



























































































































































































import { Component, Ref } from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import NationalityAutocomplete from "@/components/common/country/NationalityAutocomplete.vue";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ContractType, CrewMemberUpsertModel } from "@/api/generated";
import moment from "moment";
import { CrewMemberModel } from '../../api/generated/api';
import Discriminator from '../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    AgencyAutocomplete,
    NationalityAutocomplete,
    JobRoleAutocomplete
  }
})
export default class CrewMemberCreate extends Validation {
  @Ref() private readonly form!: VForm;

  private jobRoles: Array<string> = [];
  private maxDate: moment.Moment = moment();
  private loading = false;
  private model: CrewMemberUpsertModel = {
    countryId: "",
    agencyId: "",
    firstname: "",
    lastname: "",
    dateOfBirth: "",
    passportNumber: "",
    passportExpiry: "",
    seamansBookNumber: "",
    seamansBookIssued: new Date().toISOString(),
    seamansBookExpiry: new Date().toISOString(),
    placeOfBirth: "",
    rating: 1,
    jobRoles: [],
    discriminator: ContractType.NUMBER_1
  };

  get discriminator() {
    return Discriminator.LAND_WORKERS;
  }

  public async onEmailChanged() {
    if (!this.model.emailAddress) return;

    const response = await Api.CrewMemberService.apiCrewmemberEmailEmailAddressGet(
      this.model.emailAddress
    );

    if (!response.data) return;

    const existingMember = response.data;

    if (existingMember.discriminator !== this.model.discriminator && existingMember.discriminator !== ContractType.NUMBER_2) {
      if (!await this.showEmailPrompt('This member is already registered as a Crew Member, do you want them to be a Land Worker too?')) return;

      await Api.CrewMemberService.apiCrewmemberCrewMemberIdPut(existingMember.crewMemberId!, {
        ...existingMember,
        discriminator: ContractType.NUMBER_2
      });
    } else if (existingMember.discriminator == this.model.discriminator || existingMember.discriminator == ContractType.NUMBER_2) {
      if (!await this.showEmailPrompt('That member already exists as a Land Worker, do you wish to edit their profile?')) return;
    }

    this.$router.push({
      name: "Land Worker Edit",
      params: { crewMemberId: existingMember.crewMemberId! }
    });
  }

  private async showEmailPrompt(prompt: string, resetEmailIfFalse = true) {
    const res = await this.$confirm(prompt, { title: 'Warning' })

    if (!res && resetEmailIfFalse) this.model.emailAddress = "";

    return !!res;
  }

  private get maxAllowedDate() {
    return this.maxDate.format("yyyy-MM-DD");
  }

  private async onCreateCrewMember() {
    if (!this.form.validate()) {
      return;
    }

    this.jobRoles.forEach(x =>
      this.model.jobRoles?.push({
        jobRoleId: x
      })
    );

    try {
      this.loading = true;
      const response = await Api.CrewMemberService.apiCrewmemberPost(
        this.model
      );

      const crewMember = response.data;
      snackbarModule.setSnackbarMessage("Land worker successfully created");

      if (crewMember.crewMemberId) {
        this.$router.replace({
          name: "Land Worker Edit",
          params: { crewMemberId: crewMember.crewMemberId }
        });
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create crew member");
    } finally {
      this.loading = false;
    }
  }

  private onCancel() {
    this.$router.push({ name: "Land Workers Overview" });
  }
}
